export const PLANOGRAM_VIEW_SET = 'PLANOGRAM_VIEW_SET';
export const PLANOGRAM_VIEW_FIELD = 'PLANOGRAM_VIEW_FIELD';
export const PLANOGRAM_FIELD_HISTORY = 'PLANOGRAM_FIELD_HISTORY';
export const PLANOGRAM_FIELD_RECENTPRODS = 'PLANOGRAM_FIELD_RECENTPRODS';

export const PLANOGRAM_DETAIL_VIEW_SET ="PLANOGRAM_DETAIL_VIEW_SET";
export const PLANOGRAM_DETAIL_VIEW_FIELD = 'PLANOGRAM_DETAIL_VIEW_FIELD';
export const PLANOGRAM_DETAIL_FIELD_HISTORY = 'PLANOGRAM_DETAIL_FIELD_HISTORY';
export const PLANOGRAM_DETAIL_FIELD_RECENTPRODS = 'PLANOGRAM_DETAIL_FIELD_RECENTPRODS';

export const PLANOGRAM_VIEW_ALLOWOVERLAP = 'PLANOGRAM_VIEW_ALLOWOVERLAP';
export const PLANOGRAM_VIEW_STORE = 'PLANOGRAM_VIEW_STORE';
export const PLANOGRAM_IS_NEWDRAFT = 'PLANOGRAM_IS_NEWDRAFT';
export const PLANOGRAM_DEPARTMENT_GRID = 'PLANOGRAM_DEPARTMENT_GRID';